import React from "react"
import styles from "./QuoteWithCitation.module.css"

import headshot from "../../images/services/quoteHeadshot.png"

const QuoteWithCitation = ({
  content: { quote, quoteName, quoteRef, quoteImage },
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.quoteIcon}>
        <div className={styles.bigQuotes}>“</div>
        <div className={styles.smallQuotes}>“</div>
      </div>
      <div className={styles.quote}>{quote}</div>
      <div className={styles.citation}>
        <img src={headshot} className={styles.image} />
        <div className={styles.citationName}>{quoteName}</div>
        <div className={styles.citationRole}>{quoteRef}</div>
      </div>
    </div>
  )
}

export default QuoteWithCitation

import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesHero from "../components/ServicesHero/ServicesHero"
import ServicesSubSections from "../components/ServicesSubSections/ServicesSubSections"
import {
  heroContent,
  servicesSubContent,
} from "../content/services/servicesContent"
import styles from "./shared.module.css"
import build from "../images/services/build.svg"
import design from "../images/services/design.svg"
import ecommerce from "../images/services/ecommerce.svg"
import prototyping from "../images/services/prototyping.svg"

const ServicesPage = () => (
  <Layout darkMode>
    <SEO title="Services" />
    <ServicesHero content={heroContent} />
    <ServicesSubSections content={servicesSubContent} />
  </Layout>
)

export default ServicesPage

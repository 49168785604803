import React from "react"

import ServiceBanner from "../ServiceBanner/ServiceBanner"
import styles from "./ServicesSubSections.module.css"

const ServicesSubSections = ({ content }) => {
  return (
    <div className={styles.container}>
      {content.sections.map((item, index) => {
        return (
          <ServiceBanner
            content={item}
            title={index === 0 && content.title}
            index={index}
            isLastItem={index === content.sections.length - 1}
          />
        )
      })}
    </div>
  )
}

export default ServicesSubSections

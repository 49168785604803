export const heroContent = {
  titleContent: {
    preTitle: "Our Services",
    title: "Crafting digital products that drive results",
    subtitle:
      "From rapid prototyping and idea validation to building robust production-ready experiences - we are an independent team of builders here to help.",
  },
  contentBanners: [
    {
      preTitle: "At the idea stage?",
      title: "Rapid Product Development",
      serviceItems: ["Progressive Prototyping", "MVP Validation"],
      description:
        "Getting your products into the hands of people who'll use them is essential. Our product sprints will enable you to realise, test and validate a concept over a 3-6 week time frame. It's an ideation-led approach, focused on efficiency & balancing user needs with the project budget.",
      videoLink: "app-prototype.mp4",
      badge: undefined,
    },
    {
      preTitle: "Ready to go?",
      title: "Production. Set. Go.",
      serviceItems: [
        "Mobile Development - iOS/Android",
        "Web Development",
        "Product Audit",
      ],
      description:
        "We'll fully develop the MVP with modern technologies, avoiding future technical debt, allowing you to scale quickly! An ideal build track starts with technical setup, core flows and identifying potential risks.",
      videoLink: "full-prototype.mp4",
      badge: "badge-boutique.png",
    },
    {
      preTitle: "Upgrade your d2c presence",
      title: "Sites that Convert",
      serviceItems: ["Headless E-commerce"],
      description:
        "With years of experience as a Shopify partner, we're no stranger to the world of E-commerce. By hyper-charging this experience with a Headless approach, we aim to deliver performant experiences that your customers will love.",
      videoLink: "thrift-video.mp4",
      badge: "badge-top-shopify.png",
    },
  ],
  quoteContent: {
    quote:
      "Their personal approach and willingness to go above and beyond were impressive.",
    quoteName: "Ilana Lever",
    quoteRef: "Co-founder Motley",
    quoteImage: undefined,
  },
}

export const servicesSubContent = {
  title: "How we do it",
  sections: [
    {
      title: "Discovery & Design",
      description: [
        "Discover the future, one prototype at a time. This is about turning your challenge or idea into a solid idea and creating a prototype or wireframe to test with real people.",
      ],
      services: [
        "Wireframing",
        "User testing",
        "Rapid Prototyping",
        "Functional Design",
      ],
      image: undefined,
      blogLink: "",
      blogDescription:
        "Curious about what Progressive Prototyping is and how it can help?",
    },
    {
      title: "Web Development & Software Engineering",
      description: [
        "Developing new products and solutions is what we love to do, and it is the foundation of our work.",
        "If you're a startup, what you'll need most is an agile team that can iterate quickly, producing new versions every week so you can see real progress.",
        "If you're an established company, you want products that are testable, scalable and reliable, with excellent documentation and comprehensive code coverage. No regression, no unproductive back and forth, everything delivered on time.",
      ],
      services: [
        "Web Development",
        "Software Development",
        "End-to-end web infrastructure (Web Architecture)",
        "Headless CMS & Bespoke Content Websites",
        "Progressive Web Apps",
        "Testing & Quality Assurance (QA)",
        "Technical Consulting",
      ],
      image: undefined,
      blogLink: "",
      blogDescription: "Jamming on the Jamstack",
    },
    {
      title: "App Development",
      description: [
        "Building on our expertise with software development, we're also specialists in both iOS and Android. If you want to impress on phones, tablets, TVs or smartwatches, we've got your back. Rather than building and maintaining two codebases using different languages, we use React Native to create native apps for both Android and iOS.",
      ],
      services: [
        "React Native iOS/Android development",
        "App store setup and approval",
        "Fintech & subscription services",
      ],
      image: undefined,
      blogLink: "",
      blogDescription:
        "Learn more about Mobile App Development with React Native",
    },
    {
      title: "E-commerce",
      description: [
        "Shopify store setup, Shopify theme development, performance & site-speed review, feature integration, third-party apps, SEO & analytics.",
      ],
      services: ["Bespoke Shopify Development", "Headless E-commerce"],
      image: undefined,
      blogLink: "",
      blogDescription: "Benefits of moving to headless for E-commerce",
    },
  ],
}

import React, { useState, useRef } from "react"

import Section from "../Section"
import ButtonAngled from "../ButtonAngled"
import { useMediaQuery } from "react-responsive"

import ContentBannerVideoRight from "../ContentBannerVideoRight/ContentBannerVideoRight"
import QuoteWithCitation from "../QuoteWithCitation/QuoteWithCitation"

import homepage1 from "../../images/work/homepage1.png"
import homepage2 from "../../images/work/homepage2.png"
import homepage3 from "../../images/work/homepage3.png"

import styles from "./ServicesHero.module.css"
import classNames from "classnames"
import { Link } from "gatsby"

const VIDEO_URLS = {
  APP: "/images/app-prototype.mp4",
  WEB: "/images/web-prototype.mp4",
  ECOMM: "/images/ecomm-prototype.mp4",
}

const ServicesHero = ({
  content: {
    titleContent: { preTitle, title, subtitle },
    contentBanners,
    quoteContent,
  },
}) => {
  const [displayVideo, setDisplayVideo] = useState(false)
  const appVideoRef = useRef()
  const webVideoRef = useRef()
  const ecomVideoRef = useRef()
  const [displayVideoUrl, setDisplayVideoUrl] = useState(VIDEO_URLS.APP)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  })

  return (
    <div className={styles.container}>
      <div className={styles.coreContent}>
        <div className={styles.preTitle}>{preTitle.toUpperCase()}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.contentBanners}>
        {contentBanners.map(item => {
          return <ContentBannerVideoRight content={item} />
        })}
      </div>
      <div className={styles.ribbon} />
      <div className={styles.quoteSection}>
        <QuoteWithCitation content={quoteContent} />
      </div>
    </div>
  )
}

export default ServicesHero

import React from "react"

import CornerContainer from "../CornerContainer/CornerContainer"

import styles from "./ServiceBanner.module.css"

const ServiceBanner = ({
  content: { title, description, services, image, blogLink, blogDescription },
  index,
  isLastItem = false,
}) => {
  return (
    <div
      className={styles.bgContainer}
      style={{
        backgroundColor: index % 2 !== 0 ? "colorWhite" : "colorOffWhite",
      }}
    >
      <CornerContainer
        ignoreParallax={true}
        backgroundColor={index % 2 === 0 ? "colorWhite" : "colorOffWhite"}
        ignorePadding
        style={
          isLastItem
            ? { marginBottom: `calc(var(--cornerSize) * -1)` }
            : { paddingBottom: `var(--cornerSize)` }
        }
      >
        <div className={styles.container}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>
            {description.map(item => {
              return <div className={styles.paragraph}>{item}</div>
            })}
          </div>
          <div className={styles.lowerContainer}>
            <div className={styles.serviceItems}>
              {services.map(item => {
                return (
                  <div className={styles.itemContainer}>
                    <div className={styles.greenDot} />
                    <div className={styles.service}>{item}</div>
                  </div>
                )
              })}
            </div>
            {blogLink.length > 0 && (
              <div className={styles.articleInfo}>
                <div className={styles.articleImage}>image</div>
                <div className={styles.articleText}>{blogDescription}</div>
              </div>
            )}
          </div>
        </div>
      </CornerContainer>
    </div>
  )
}

export default ServiceBanner

import React from "react"
import { Link } from "gatsby"

import styles from "./ContentBannerVideoRight.module.css"

const ContentBannerVideoRight = ({
  content: { preTitle, title, serviceItems, description, videoLink, badge },
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.preTitle}>{preTitle.toUpperCase()}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.serviceItemsContainer}>
          {serviceItems.map(item => {
            return <div className={styles.serviceItem}>{item}</div>
          })}
        </div>
        <div className={styles.description}>{description}</div>
        <div className={styles.getStartedContainer}>
          <Link to="/contact" className={styles.getStarted}>
            <div>Get Started</div>
          </Link>
          {/* <div className={styles.learnMore}>Learn More</div> */}
        </div>
      </div>
      <div className={styles.videoContainer}>
        <div className={styles.videoContentContainer}>
          <video src={`/images/${videoLink}`} playsInline autoPlay loop muted />
        </div>
      </div>
      {badge && (
        <img
          src={`/images/${badge}`}
          alt="clutch badge"
          className={styles.videoBadge}
        />
      )}
    </div>
  )
}

export default ContentBannerVideoRight
